import { Offer } from "../Offer";

export const OfferContent = () => {
  return (
    <>
      <div className="modal-header">
        <img src="/images/title.svg" alt="" className="title" />
        <img src="/images/sub-title.svg" alt="" className="sub-title" />
      </div>
      <div className="modal-content">
        <Offer>
          <div className="offer-container">
            <img src="/images/espadilha-white-icon.svg" alt="" />
            <span>20 Rodadas grátis </span>| Depositar R$10
          </div>
        </Offer>
        <Offer>
          <div className="offer-container">
            <img src="/images/espadilha-white-icon.svg" alt="" />
            <span>40 Rodadas grátis </span>| Depositar R$20
          </div>
        </Offer>
        <Offer>
          <div className="offer-container">
            <img src="/images/espadilha-white-icon.svg" alt="" />
            <span>100 Rodadas grátis </span>| Depositar R$50
          </div>
        </Offer>

        <div className="btn">
          <Offer className="btn">finalizar cadastro</Offer>
        </div>
      </div>

      <div>
        <img id="logo" src="/images/cassinopix-logo.svg" alt="" />
      </div>
    </>
  );
};
